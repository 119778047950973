$(window).on('load', () => {
  const $wrapper = $('.c-file-input-wrapper');

  if (!$wrapper) { return; }

  const $input = $wrapper.find('.c-file-input__input');
  const $filename = $wrapper.find('.c-file-input__filename');

  $input.on('change', (e) => {
    $filename.html('');

    const $target = $(e.target);
    const files = Array.from($target.prop('files'));

    const maxFileCount = $target.data('max-file-count');
    if (maxFileCount && files.length > maxFileCount) {
      alert(`添付ファイルは${maxFileCount}個までアップロード可能です`);
      e.preventDefault();
      $target.prop('value', ''); // RESET
      return;
    }

    const filenames = files.map(f => f.name);

    filenames.forEach((f) => {
      $filename.append($('<span>').text(f));
      $filename.append($('<br>'));
    });
  });
});
